import Image from "next/image";
import React from "react";
import { useMediaQuery, useToggle } from "../../../../hooks";
import { RequestModal } from "../RequestSection/RequestModal";
import clsx from "clsx";

const LandingBanner = ({ image, imageMobile }) => {
  const [showModal, toggle, off] = useToggle();
  const isDesktop = useMediaQuery("(min-width:500px)");

  return (
    ((isDesktop && !!image) || (!isDesktop && !!imageMobile)) && (
      <section className="landing-banner landing-main container">
        {showModal && <RequestModal onClose={off} />}
        <button
          className={clsx("landing-banner__wrapper", {
            ["landing-banner__wrapper--desktop"]: isDesktop,
          })}
          onClick={toggle}
        >
          {isDesktop ? (
            <Image src={image} layout="fill" alt="banner" priority={true} />
          ) : (
            <Image
              src={imageMobile}
              layout="fill"
              alt="bannerMobile"
              priority={true}
            />
          )}
        </button>
        <style jsx>{`
          @import "./LandingBanner.scss?7ssss";
        `}</style>
      </section>
    )
  );
};

export { LandingBanner };
